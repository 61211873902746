<template>
  <q-form ref="editForm">
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <!-- 기본정보 -->
        <c-card title="LBLBASEINFO" class="cardClassDetailForm">
          <template slot="card-button">
            <q-btn-group outline >
              <c-btn
                v-if="editable"
                :url="saveUrl"
                :isSubmit="isSave"
                :param="resultData"
                :mappingType="saveType"
                label="저장"
                icon="save"
                @beforeAction="saveData"
                @btnCallback="saveCallback" 
              />
              <c-btn 
                v-if="editable && isOld" 
                label="삭제" 
                icon="remove" 
                @btnClicked="deleteData"/>
            </q-btn-group>
          </template>
          <template slot="card-detail">
            <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
              <!-- 검사일 -->
              <c-plant
                :required="true"
                :editable="editable"
                label="사업장"
                name="plantCd"
                v-model="resultData.plantCd"
              >
              </c-plant>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
              <!-- 검사일 -->
              <c-datepicker
                :required="true"
                :editable="editable"
                default="today"
                label="검사일"
                name="inspectionDate"
                v-model="resultData.inspectionDate"
              >
              </c-datepicker>
            </div>
            <!-- <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
              <c-dept
                :editable="editable"
                label="주관부서"
                type="edit"
                name="departmentDeptCd"
                v-model="resultData.departmentDeptCd"
              >
              </c-dept>
            </div> -->
            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <!-- 검사업체 -->
              <c-text
                :editable="editable"
                label="검사업체"
                name="inspectionAgency"
                v-model="resultData.inspectionAgency">
              </c-text>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
              <!-- 검사결과 상세 -->
              <c-textarea
                :editable="editable"
                label="검사결과 상세"
                name="inspectionResultDetail"
                v-model="resultData.inspectionResultDetail">
              </c-textarea>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <c-upload 
                :attachInfo="attachInfo"
                :editable="editable"
                label="검사결과 업로드">
              </c-upload>
            </div>
          </template>
        </c-card>
      </div>
      <c-dialog :param="popupOptions"></c-dialog>
    </div>
  </q-form>
</template>
<script>
import { uid } from "quasar";
import selectConfig from "@/js/selectConfig";
import transactionConfig from "@/js/transactionConfig";
export default {
  name: "hazard-equipment-result-register-detail",
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        hhmHazardousMachineryInspectionRsltId: '',
      }),
    },
  },
  data() {
    return {
      editable: true,
      popupOptions: {
        target: null,
        title: "",
        visible: false,
        top: "",
        param: {},
        closeCallback: null,
      },
      resultData: {
        hhmHazardousMachineryInspectionRsltId: '',  // 검사 일련번호
        plantCd: null,  // 사업장
        inspectionDate: '',  // 검사일자
        inspectionAgency: '',  // 검사 업체
        inspectionResultDetail: '',  // 검사결과 상세
      },
      disabled: false,
      getUrl: "",
      saveUrl: '',
      deleteUrl: '',
      saveType: 'POST',
      insertUrl: '',
      updateUrl: '',
      isSave: false,
      attachInfo: {
        isSubmit: '',
        taskClassCd: 'HAZARD_EQUIPMENT_INSP_RSLT',
        taskKey: '',
      },
    };
  },

  beforeCreate() {},
  created() {},
  beforeMount() {},
  mounted() {
      this.init();
  },
  beforeDestroy() {},
  computed: {
    isOld() {
      return Boolean(this.popupParam.hhmHazardousMachineryInspectionRsltId)
    }
  },
  watch: {
  },
  methods: {
    init() {
      this.getUrl = selectConfig.sop.hhm.hazard.inspResult.get.url;
      this.saveUrl = transactionConfig.sop.hhm.hazard.inspResult.insert.url;
      this.insertUrl = transactionConfig.sop.hhm.hazard.inspResult.insert.url;
      this.updateUrl = transactionConfig.sop.hhm.hazard.inspResult.update.url;
      this.deleteUrl = transactionConfig.sop.hhm.hazard.inspResult.delete.url;
      this.getDetail();
    },
    getDetail() {
      if (this.popupParam.hhmHazardousMachineryInspectionRsltId) {
        this.$http.url = this.$format(this.getUrl, this.popupParam.hhmHazardousMachineryInspectionRsltId);
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.resultData = this.$_.clone(_result.data);
          this.$set(this.attachInfo, 'taskKey', this.popupParam.hhmHazardousMachineryInspectionRsltId)
        },);
      } 
    },
    saveData() {
      if (this.popupParam.hhmHazardousMachineryInspectionRsltId) {
        this.saveUrl = this.updateUrl;
        this.saveType = 'PUT';
      } else {
        this.saveUrl = this.insertUrl;
        this.saveType = 'POST';
      }
      this.$refs['editForm'].validate().then(_result => {
        if (_result) { 
          window.getApp.$emit('CONFIRM', {
            title: 'LBLCONFIRM',
            message: 'MSGSAVE', // 저장하시겠습니까?
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.resultData.regUserId = this.$store.getters.user.userId;
              this.resultData.chgUserId = this.$store.getters.user.userId;

              this.isSave = !this.isSave;
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          })
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    saveCallback(result) {
      window.getApp.$emit("APP_REQUEST_SUCCESS");
      this.$set(this.attachInfo, 'taskKey', result.data)
      this.$set(this.attachInfo, 'isSubmit', uid())
      this.popupParam.hhmHazardousMachineryInspectionRsltId = result.data
      this.getDetail();
    },
    deleteData() {
      window.getApp.$emit('CONFIRM', {
        title: '확인',
        message: '삭제하시겠습니까?',
        // TODO : 필요시 추가하세요.
        type: 'warning', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.$http.url = this.$format(this.deleteUrl, this.popupParam.hhmHazardousMachineryInspectionRsltId);
          this.$http.type = 'DELETE';
          this.$http.request(() => {
            window.getApp.$emit('APP_REQUEST_SUCCESS');
            this.$emit('closePopup');
          },);
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
  },
};
</script>
