var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("q-form", { ref: "editForm" }, [
    _c(
      "div",
      { staticClass: "row" },
      [
        _c(
          "div",
          { staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" },
          [
            _c(
              "c-card",
              {
                staticClass: "cardClassDetailForm",
                attrs: { title: "LBLBASEINFO" },
              },
              [
                _c(
                  "template",
                  { slot: "card-button" },
                  [
                    _c(
                      "q-btn-group",
                      { attrs: { outline: "" } },
                      [
                        _vm.editable
                          ? _c("c-btn", {
                              attrs: {
                                url: _vm.saveUrl,
                                isSubmit: _vm.isSave,
                                param: _vm.resultData,
                                mappingType: _vm.saveType,
                                label: "저장",
                                icon: "save",
                              },
                              on: {
                                beforeAction: _vm.saveData,
                                btnCallback: _vm.saveCallback,
                              },
                            })
                          : _vm._e(),
                        _vm.editable && _vm.isOld
                          ? _c("c-btn", {
                              attrs: { label: "삭제", icon: "remove" },
                              on: { btnClicked: _vm.deleteData },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c("template", { slot: "card-detail" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2",
                    },
                    [
                      _c("c-plant", {
                        attrs: {
                          required: true,
                          editable: _vm.editable,
                          label: "사업장",
                          name: "plantCd",
                        },
                        model: {
                          value: _vm.resultData.plantCd,
                          callback: function ($$v) {
                            _vm.$set(_vm.resultData, "plantCd", $$v)
                          },
                          expression: "resultData.plantCd",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2",
                    },
                    [
                      _c("c-datepicker", {
                        attrs: {
                          required: true,
                          editable: _vm.editable,
                          default: "today",
                          label: "검사일",
                          name: "inspectionDate",
                        },
                        model: {
                          value: _vm.resultData.inspectionDate,
                          callback: function ($$v) {
                            _vm.$set(_vm.resultData, "inspectionDate", $$v)
                          },
                          expression: "resultData.inspectionDate",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6",
                    },
                    [
                      _c("c-text", {
                        attrs: {
                          editable: _vm.editable,
                          label: "검사업체",
                          name: "inspectionAgency",
                        },
                        model: {
                          value: _vm.resultData.inspectionAgency,
                          callback: function ($$v) {
                            _vm.$set(_vm.resultData, "inspectionAgency", $$v)
                          },
                          expression: "resultData.inspectionAgency",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12",
                    },
                    [
                      _c("c-textarea", {
                        attrs: {
                          editable: _vm.editable,
                          label: "검사결과 상세",
                          name: "inspectionResultDetail",
                        },
                        model: {
                          value: _vm.resultData.inspectionResultDetail,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.resultData,
                              "inspectionResultDetail",
                              $$v
                            )
                          },
                          expression: "resultData.inspectionResultDetail",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12" },
                    [
                      _c("c-upload", {
                        attrs: {
                          attachInfo: _vm.attachInfo,
                          editable: _vm.editable,
                          label: "검사결과 업로드",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ],
              2
            ),
          ],
          1
        ),
        _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }